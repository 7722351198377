import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Paper,
    CircularProgress,
    Alert,
    Button,
} from '@mui/material';
import { getFirestore, collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { AuthContext } from '../../../contexts/AuthContext';
import ReactMarkdown from 'react-markdown';
import { Restaurant as RestaurantIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const DietView = ({ clientId }) => {
    const [dieta, setDieta] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDieta = async () => {
            try {
                console.log('Buscando dieta para:', {
                    IDPT: userDetails.IDPT,
                    clientId: clientId,
                });
                
                const dietsRef = collection(db, 'diets');
                const q = query(
                    dietsRef,
                    where('IDPT', '==', userDetails.IDPT),
                    where('clientId', '==', clientId),
                    orderBy('createdAt', 'desc'),
                    limit(1)
                );
                const querySnapshot = await getDocs(q);
                
                console.log('Resultado de la búsqueda:', querySnapshot.size, 'documentos');
                
                if (!querySnapshot.empty) {
                    const dietaData = querySnapshot.docs[0].data();
                    console.log('Dieta encontrada:', dietaData);
                    setDieta(dietaData);
                } else {
                    console.log('No se encontraron dietas');
                }
            } catch (error) {
                console.error('Error al cargar la dieta:', error);
                setError('Error al cargar la dieta del cliente');
            } finally {
                setLoading(false);
            }
        };

        if (userDetails?.IDPT && clientId) {
            fetchDieta();
        }
    }, [clientId, userDetails?.IDPT, db]);

    const handleCreateDiet = () => {
        navigate('/generar', { state: { clientId, type: 'dieta' } });
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ m: 3 }}>
                {error}
            </Alert>
        );
    }

    if (!dieta) {
        return (
            <Box sx={{ p: 3 }}>
                <Paper 
                    elevation={1} 
                    sx={{ 
                        p: 4, 
                        borderRadius: 2,
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <RestaurantIcon 
                        sx={{ 
                            fontSize: 60,
                            color: 'primary.main',
                            mb: 2
                        }}
                    />
                    <Typography variant="h6" gutterBottom>
                        No hay plan de alimentación asignado
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                        Este cliente aún no tiene un plan de alimentación asignado. 
                        Puedes crear uno nuevo haciendo clic en el botón de abajo.
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleCreateDiet}
                        sx={{
                            background: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
                            color: 'white',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #388E3C 30%, #4CAF50 90%)',
                            }
                        }}
                    >
                        Crear Plan de Alimentación
                    </Button>
                </Paper>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={1} sx={{ p: 3, borderRadius: 2 }}>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Plan de Alimentación
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Última actualización: {new Date(dieta.createdAt.toDate()).toLocaleDateString()}
                    </Typography>
                </Box>
                
                <Box sx={{ 
                    mt: 2,
                    '& p': { mb: 2 },
                    '& h1': { fontSize: '1.8rem', mb: 2, color: 'primary.main' },
                    '& h2': { fontSize: '1.5rem', mb: 2, color: 'primary.main' },
                    '& h3': { fontSize: '1.2rem', mb: 1.5 },
                    '& ul, & ol': { mb: 2, pl: 3 },
                    '& li': { mb: 1 }
                }}>
                    <ReactMarkdown>
                        {dieta.content}
                    </ReactMarkdown>
                </Box>
            </Paper>
        </Box>
    );
};

export default DietView; 