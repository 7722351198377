import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Tabs,
    Tab,
    Paper,
    CircularProgress,
    IconButton,
    Tooltip,
    Avatar,
    Typography,
    Chip,
    Divider
} from '@mui/material';
import { 
    Person as PersonIcon,
    Dashboard as DashboardIcon,
    Settings as SettingsIcon,
    FitnessCenter as FitnessCenterIcon,
    ArrowBack as ArrowBackIcon,
    Restaurant as RestaurantIcon
} from '@mui/icons-material';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { AuthContext } from '../../contexts/AuthContext';

// Importar las vistas
import SummaryView from './views/SummaryView';
import ProfileView from './views/ProfileView';
import RoutineView from './views/RoutineView';
import ManagementView from './views/ManagementView';
import DietView from './views/DietView';

const ClientDetailsV2 = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState(null);
    const [error, setError] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();

    useEffect(() => {
        const fetchClientDetails = async () => {
            if (!userDetails || !id) {
                setError("Información de usuario o ID de cliente no disponible");
                setLoading(false);
                return;
            }

            try {
                let clientDocRef;

                if (userDetails.tipo_profesional === 'entrenador_independiente') {
                    clientDocRef = doc(db, 'userDetails', userDetails.uid, 'Clientes_asociados', id);
                } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                    if (!userDetails.IDBO) {
                        throw new Error('ID del Centro (IDBO) no disponible');
                    }
                    clientDocRef = doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', id);
                }

                const clientDoc = await getDoc(clientDocRef);

                if (!clientDoc.exists()) {
                    throw new Error("Cliente no encontrado");
                }

                const clientData = clientDoc.data();
                setClient({
                    ...clientData,
                    id: id
                });
            } catch (error) {
                console.error("Error al obtener detalles del cliente:", error);
                setError("Error al cargar los detalles del cliente");
            } finally {
                setLoading(false);
            }
        };

        fetchClientDetails();
    }, [id, userDetails, db]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleGoBack = () => {
        navigate('/clients');
    };

    const tabs = [
        { label: 'Resumen', icon: <DashboardIcon /> },
        { label: 'Perfil', icon: <PersonIcon /> },
        { label: 'Rutina', icon: <FitnessCenterIcon /> },
        { label: 'Dieta', icon: <RestaurantIcon /> },
        { label: 'Gestión', icon: <SettingsIcon /> }
    ];

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {/* Header con información del cliente */}
            <Paper 
                elevation={0} 
                sx={{ 
                    borderRadius: 0,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    mb: 2,
                    p: 2
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Tooltip title="Volver a la lista de clientes">
                        <IconButton onClick={handleGoBack}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>
                    
                    <Avatar
                        src={client?.photo_url}
                        alt={`${client?.nombre} ${client?.apellidos}`}
                        sx={{ 
                            width: 56, 
                            height: 56,
                            border: '2px solid',
                            borderColor: 'primary.main'
                        }}
                    />
                    
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" component="h1">
                            {client?.nombre} {client?.apellidos}
                        </Typography>
                        <Chip
                            label={client?.plan === 'Pro' ? 'Cliente Pro' : 'Cliente Free'}
                            color={client?.plan === 'Pro' ? 'primary' : 'default'}
                            size="small"
                            sx={{
                                background: client?.plan === 'Pro' ? 'linear-gradient(45deg, #FFA500 30%, #FF1493 90%)' : undefined,
                                color: client?.plan === 'Pro' ? 'white' : undefined,
                            }}
                        />
                    </Box>
                </Box>
            </Paper>

            {/* Tabs */}
            <Paper elevation={0} sx={{ borderRadius: 0 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.label}
                                icon={tab.icon}
                                iconPosition="start"
                                sx={{
                                    minHeight: 48,
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                    fontWeight: 'medium'
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>
            </Paper>

            <Box sx={{ mt: 2 }}>
                {currentTab === 0 && <SummaryView clientId={id} />}
                {currentTab === 1 && <ProfileView clientId={id} />}
                {currentTab === 2 && <RoutineView clientId={id} />}
                {currentTab === 3 && <DietView clientId={id} />}
                {currentTab === 4 && <ManagementView clientId={id} />}
            </Box>
        </Box>
    );
};

export default ClientDetailsV2; 