import React, { useState, useContext, useEffect } from 'react';
import { 
    Typography, 
    Box, 
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    Alert,
    CircularProgress,
    MenuItem
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import clientsBackground from '../../assets/images/imagen-dashboard-clients.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@mui/icons-material/Close';
import { AuthContext } from '../../contexts/AuthContext';
import { db } from '../../config/firebaseConfig';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

const AddClient = () => {
    const { currentUser, userDetails } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Estado para el formulario
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        nombre: '',
        apellidos: '',
        fechaNacimiento: null,
        sexo: '',
        peso: '',
        objetivoagua: '',
        objetivocalorias: '',
        objetivograsas: '',
        objetivohidratos: '',
        objetivoprote: '',
        objetivotiempo: '',
        objetivopasos: '',
        tipodieta: ''
    });

    const handleCreateUser = async () => {
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            const auth = getAuth();
            // Guardar la referencia del usuario actual (profesional)
            const currentAuthUser = auth.currentUser;
            
            // Crear usuario en Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const newUser = userCredential.user;

            // Preparar datos base del usuario
            const baseUserData = {
                uid: newUser.uid,
                email: formData.email,
                nombre: formData.nombre,
                apellidos: formData.apellidos,
                fechanacimiento: formData.fechaNacimiento,
                sexo: formData.sexo,
                peso: Number(formData.peso) || 0,
                objetivoagua: Number(formData.objetivoagua) || 0,
                objetivocalorias: Number(formData.objetivocalorias) || 0,
                objetivograsas: Number(formData.objetivograsas) || 0,
                objetivohidratos: Number(formData.objetivohidratos) || 0,
                objetivoprote: Number(formData.objetivoprote) || 0,
                objetivotiempo: Number(formData.objetivotiempo) || 0,
                objetivopasos: Number(formData.objetivopasos) || 0,
                tipodieta: formData.tipodieta || 'normal',
                plan: 'Pro',
                tipo_usuario: 'cliente',
                tiene_entrenador: true,
                en_solicitud: false,
                solicitud_aceptada: true,
                uidcliente: newUser.uid
            };

            let userData = { ...baseUserData };

            // Añadir campos específicos según el tipo de profesional
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                userData = {
                    ...userData,
                    IDPT: userDetails.IDPT,
                    IDBO: null
                };

                // Crear documento en userDetails
                await setDoc(doc(db, 'userDetails', newUser.uid), userData);

                // Crear en Clientes_asociados
                await setDoc(doc(db, 'userDetails', currentUser.uid, 'Clientes_asociados', newUser.uid), userData);

            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                userData = {
                    ...userData,
                    IDBO: userDetails.IDBO,
                    IDPT: null
                };

                // Crear documento en userDetails
                await setDoc(doc(db, 'userDetails', newUser.uid), userData);

                // Crear en Clientes_asociados_centros
                await setDoc(doc(db, 'Centros', userDetails.IDBO, 'Clientes_asociados_centros', newUser.uid), userData);
            }

            // Volver a iniciar sesión con el usuario profesional
            await auth.updateCurrentUser(currentAuthUser);

            setSuccess('Usuario creado exitosamente');
            setFormData({
                email: '',
                password: '',
                nombre: '',
                apellidos: '',
                fechaNacimiento: null,
                sexo: '',
                peso: '',
                objetivoagua: '',
                objetivocalorias: '',
                objetivograsas: '',
                objetivohidratos: '',
                objetivoprote: '',
                objetivotiempo: '',
                objetivopasos: '',
                tipodieta: ''
            });

        } catch (error) {
            console.error('Error al crear usuario:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (field) => (event) => {
        setFormData(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    };

    return (
        <Box 
            sx={{ 
                flexGrow: 1, 
                p: 3,
                position: 'relative',
                minHeight: 'calc(100vh - 64px)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url(${clientsBackground})`,
                    backgroundSize: '50%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.1,
                    zIndex: -1,
                }
            }}
        >
            <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4 }}>
                <Typography variant="h5" gutterBottom sx={{ mb: 4, textAlign: 'center' }}>
                    Crear Nuevo Cliente
                </Typography>
                
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {success}
                    </Alert>
                )}
                
                <Grid container spacing={2}>
                    {/* Información básica */}
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mb: 2, color: 'primary.main' }}>
                            Información Básica
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            value={formData.email}
                            onChange={handleInputChange('email')}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Contraseña"
                            type="password"
                            value={formData.password}
                            onChange={handleInputChange('password')}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Nombre"
                            value={formData.nombre}
                            onChange={handleInputChange('nombre')}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Apellidos"
                            value={formData.apellidos}
                            onChange={handleInputChange('apellidos')}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                            <DatePicker
                                label="Fecha de Nacimiento"
                                value={formData.fechaNacimiento}
                                onChange={(newValue) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        fechaNacimiento: newValue
                                    }));
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Sexo</InputLabel>
                            <Select
                                value={formData.sexo}
                                label="Sexo"
                                onChange={handleInputChange('sexo')}
                            >
                                <MenuItem value="hombre">Hombre</MenuItem>
                                <MenuItem value="mujer">Mujer</MenuItem>
                                <MenuItem value="otro">Otro</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Objetivos y medidas */}
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mb: 2, mt: 2, color: 'primary.main' }}>
                            Objetivos y Medidas
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Peso (kg)"
                            type="number"
                            value={formData.peso}
                            onChange={handleInputChange('peso')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Objetivo de agua (ml)"
                            type="number"
                            value={formData.objetivoagua}
                            onChange={handleInputChange('objetivoagua')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Objetivo de calorías"
                            type="number"
                            value={formData.objetivocalorias}
                            onChange={handleInputChange('objetivocalorias')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Objetivo de grasas (g)"
                            type="number"
                            value={formData.objetivograsas}
                            onChange={handleInputChange('objetivograsas')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Objetivo de hidratos (g)"
                            type="number"
                            value={formData.objetivohidratos}
                            onChange={handleInputChange('objetivohidratos')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Objetivo de proteínas (g)"
                            type="number"
                            value={formData.objetivoprote}
                            onChange={handleInputChange('objetivoprote')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Objetivo de tiempo de ejercicio (min)"
                            type="number"
                            value={formData.objetivotiempo}
                            onChange={handleInputChange('objetivotiempo')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Objetivo de pasos"
                            type="number"
                            value={formData.objetivopasos}
                            onChange={handleInputChange('objetivopasos')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Tipo de Dieta</InputLabel>
                            <Select
                                value={formData.tipodieta}
                                label="Tipo de Dieta"
                                onChange={handleInputChange('tipodieta')}
                            >
                                <MenuItem value="normal">Normal</MenuItem>
                                <MenuItem value="vegetariana">Vegetariana</MenuItem>
                                <MenuItem value="vegana">Vegana</MenuItem>
                                <MenuItem value="pescetariana">Pescetariana</MenuItem>
                                <MenuItem value="cetogenica">Cetogénica</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={handleCreateUser}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : null}
                        sx={{ minWidth: 200 }}
                    >
                        Crear Cliente
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default AddClient;
