import React from 'react';
import { Typography, Box } from '@mui/material';

const Footer = () => {
    return (
        <Box 
            component="footer" 
            sx={{ 
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                py: 2, 
                backgroundColor: 'background.paper',
                borderTop: '1px solid',
                borderColor: 'divider',
                zIndex: 1000,
                textAlign: 'center',
                marginLeft: '240px', // Ancho de la Navbar
            }}
        >
            <Typography variant="body2" color="text.secondary">
                © 2025 Fitai Labs SL. Todos los derechos reservados.
            </Typography>
        </Box>
    );
};

export default Footer;