import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Alert,
    Snackbar
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import deleteClientService from '../../../services/deleteClient';
import { useAuth } from '../../../contexts/AuthContext';

const ManagementView = ({ clientId }) => {
    const navigate = useNavigate();
    const { userDetails } = useAuth();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleDeleteClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        try {
            await deleteClientService(userDetails, clientId);
            setSnackbar({
                open: true,
                message: 'Cliente eliminado correctamente',
                severity: 'success'
            });
            // Esperar un momento para que el usuario vea el mensaje de éxito
            setTimeout(() => {
                navigate('/clients');
            }, 1500);
        } catch (error) {
            console.error('Error al eliminar el cliente:', error);
            setSnackbar({
                open: true,
                message: `Error al eliminar el cliente: ${error.message}`,
                severity: 'error'
            });
            setOpenDialog(false);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
                    Gestión del Cliente
                </Typography>

                <Box sx={{ 
                    p: 3, 
                    bgcolor: 'error.light', 
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'error.main',
                }}>
                    <Typography variant="h6" gutterBottom color="error.dark">
                        Zona de Peligro
                    </Typography>
                    <Typography variant="body1" paragraph color="error.dark">
                        Las acciones en esta sección son irreversibles. Por favor, procede con precaución.
                    </Typography>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={handleDeleteClick}
                        disabled={loading}
                    >
                        {loading ? 'Eliminando...' : 'Eliminar Cliente'}
                    </Button>
                </Box>
            </Paper>

            {/* Diálogo de confirmación */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Estás seguro de que quieres eliminar este cliente?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Esta acción eliminará al cliente de tu lista y actualizará su perfil. 
                        Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleCloseDialog} 
                        disabled={loading}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleConfirmDelete} 
                        color="error" 
                        variant="contained"
                        disabled={loading}
                        autoFocus
                    >
                        {loading ? 'Eliminando...' : 'Eliminar'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar para notificaciones */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ManagementView; 