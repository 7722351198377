import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    Typography,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Alert,
    Button,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    ImageList,
    ImageListItem,
    IconButton,
    TextField,
    Menu,
    MenuItem,
    Checkbox,
    ListItemText,
    DialogActions
} from '@mui/material';
import {
    ExpandMore as ExpandMoreIcon,
    FitnessCenter as FitnessCenterIcon,
    Info as InfoIcon,
    VideoLibrary as VideoLibraryIcon,
    Add as AddIcon,
    Close as CloseIcon,
    Search as SearchIcon,
    Image as ImageIcon,
    CalendarMonth as CalendarIcon,
    Check as CheckIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';
import { getFirestore, collection, query, where, getDocs, orderBy, limit, updateDoc, doc } from 'firebase/firestore';
import { AuthContext } from '../../../contexts/AuthContext';

const RoutineView = ({ clientId }) => {
    const [rutina, setRutina] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openResourceModal, setOpenResourceModal] = useState(false);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [recursos, setRecursos] = useState([]);
    const [loadingRecursos, setLoadingRecursos] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedType, setSelectedType] = useState('all');
    const [previewResource, setPreviewResource] = useState(null);
    const { userDetails } = useContext(AuthContext);
    const db = getFirestore();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDayIndex, setSelectedDayIndex] = useState(null);
    const [editingField, setEditingField] = useState(null);
    const [editingValue, setEditingValue] = useState('');
    const [editingDayIndex, setEditingDayIndex] = useState(null);
    const [editingExerciseIndex, setEditingExerciseIndex] = useState(null);
    const [openNewExerciseModal, setOpenNewExerciseModal] = useState(false);
    const [newExerciseDayIndex, setNewExerciseDayIndex] = useState(null);
    const [newExercise, setNewExercise] = useState({
        name: '',
        sets: '',
        reps: '',
        instructions: ''
    });
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteExerciseData, setDeleteExerciseData] = useState(null);
    const [openNewDayModal, setOpenNewDayModal] = useState(false);
    const [newDay, setNewDay] = useState({
        dayName: '',
        weekDays: []
    });
    const [deleteDayConfirmOpen, setDeleteDayConfirmOpen] = useState(false);
    const [deleteDayIndex, setDeleteDayIndex] = useState(null);

    const diasSemana = [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo'
    ];

    useEffect(() => {
        const fetchRutina = async () => {
            try {
                console.log('Valores de búsqueda:', {
                    IDPT: userDetails.IDPT,
                    clientId: clientId,
                });
                
                // Primero intentemos obtener todos los documentos sin filtros para ver qué hay
                const routinesRef = collection(db, 'routines');
                const allRoutinesQuery = query(routinesRef);
                const allRoutines = await getDocs(allRoutinesQuery);
                
                console.log('Total de rutinas en la colección:', allRoutines.size);
                allRoutines.forEach(doc => {
                    console.log('Rutina encontrada:', {
                        id: doc.id,
                        IDPT: doc.data().IDPT,
                        clientId: doc.data().clientId,
                        createdAt: doc.data().createdAt
                    });
                });

                // Ahora hacemos la consulta con filtros
                const q = query(
                    routinesRef,
                    where('IDPT', '==', userDetails.IDPT),
                    where('clientId', '==', clientId),
                    orderBy('createdAt', 'desc'),
                    limit(1)
                );
                const querySnapshot = await getDocs(q);
                
                console.log('Resultado de la búsqueda con filtros:', querySnapshot.size, 'documentos');
                
                if (!querySnapshot.empty) {
                    const rutinaData = querySnapshot.docs[0].data();
                    console.log('Rutina encontrada con filtros:', rutinaData);
                    setRutina(rutinaData);
                } else {
                    console.log('No se encontraron rutinas con los filtros aplicados');
                }
            } catch (error) {
                console.error('Error al cargar la rutina:', error);
                setError('Error al cargar la rutina del cliente');
            } finally {
                setLoading(false);
            }
        };

        if (userDetails?.IDPT && clientId) {
            fetchRutina();
        }
    }, [clientId, userDetails?.IDPT, db]);

    const fetchRecursos = async () => {
        setLoadingRecursos(true);
        try {
            let recursosRef;
            if (userDetails.tipo_profesional === 'entrenador_independiente') {
                recursosRef = collection(db, 'userDetails', userDetails.uid, 'recursos');
            } else if (['propietario_centro', 'trabajador_centro'].includes(userDetails.tipo_profesional)) {
                recursosRef = collection(db, 'Centros', userDetails.IDBO, 'recursos');
            }

            const recursosSnapshot = await getDocs(recursosRef);
            const recursosData = recursosSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setRecursos(recursosData);
        } catch (error) {
            console.error("Error al cargar recursos:", error);
        } finally {
            setLoadingRecursos(false);
        }
    };

    const handleOpenResourceModal = (exercise, dayIndex, exerciseIndex) => {
        // Encontrar el índice real del día en el array
        const realDayIndex = rutina.days.findIndex(d => d.dayIndex === dayIndex);
        setSelectedExercise({ ...exercise, dayIndex: realDayIndex, exerciseIndex });
        setOpenResourceModal(true);
        fetchRecursos();
    };

    const handleCloseResourceModal = () => {
        setOpenResourceModal(false);
        setSelectedExercise(null);
    };

    const handleAddResource = async (resource) => {
        if (!selectedExercise || !rutina) return;

        try {
            // Crear una copia profunda de la rutina para evitar problemas de referencia
            const newRutina = JSON.parse(JSON.stringify(rutina));
            
            // Verificar que el día y el ejercicio existen
            if (!newRutina.days || !newRutina.days[selectedExercise.dayIndex]) {
                throw new Error('Día no encontrado en la rutina');
            }

            const day = newRutina.days[selectedExercise.dayIndex];
            if (!day.exercises || !day.exercises[selectedExercise.exerciseIndex]) {
                throw new Error('Ejercicio no encontrado en el día');
            }

            const exercise = day.exercises[selectedExercise.exerciseIndex];
            
            // Inicializar el array de recursos si no existe
            if (!exercise.recursos) {
                exercise.recursos = [];
            }

            // Evitar duplicados
            if (!exercise.recursos.find(r => r.id === resource.id)) {
                exercise.recursos.push({
                    id: resource.id,
                    tipo: resource.fileType,
                    url: resource.fileUrl,
                    titulo: resource.titulo
                });
            }

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: new Date().toISOString()
                });
                setRutina(newRutina);
                handleCloseResourceModal();
            }
        } catch (error) {
            console.error("Error al añadir recurso:", error);
            alert(`Error al añadir el recurso al ejercicio: ${error.message}`);
        }
    };

    const handleDeleteResource = async (dayIndex, exerciseIndex, resourceId) => {
        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            // Encontrar el índice real del día
            const realDayIndex = newRutina.days.findIndex(d => d.dayIndex === dayIndex);
            if (realDayIndex === -1) throw new Error('Día no encontrado');
            
            const exercise = newRutina.days[realDayIndex].exercises[exerciseIndex];
            if (!exercise) throw new Error('Ejercicio no encontrado');
            
            exercise.recursos = exercise.recursos.filter(r => r.id !== resourceId);

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: new Date().toISOString()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al eliminar recurso:", error);
            alert('Error al eliminar el recurso del ejercicio: ' + error.message);
        }
    };

    const handleDayClick = (event, dayIndex) => {
        setAnchorEl(event.currentTarget);
        setSelectedDayIndex(dayIndex);
    };

    const handleDayClose = () => {
        setAnchorEl(null);
        setSelectedDayIndex(null);
    };

    const handleDaySelect = async (weekDay) => {
        if (!rutina || selectedDayIndex === null) return;

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const dayToUpdate = newRutina.days.find(d => d.dayIndex === selectedDayIndex);

            if (!dayToUpdate) {
                throw new Error('Día no encontrado');
            }

            // Inicializar el array de días de la semana si no existe
            if (!dayToUpdate.weekDays) {
                dayToUpdate.weekDays = [];
            }

            // Toggle el día seleccionado
            const index = dayToUpdate.weekDays.indexOf(weekDay);
            if (index === -1) {
                dayToUpdate.weekDays.push(weekDay);
            } else {
                dayToUpdate.weekDays.splice(index, 1);
            }

            // Ordenar los días según el orden de la semana
            dayToUpdate.weekDays.sort((a, b) => diasSemana.indexOf(a) - diasSemana.indexOf(b));

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: new Date().toISOString()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al actualizar días de la semana:", error);
            alert('Error al actualizar los días de la semana: ' + error.message);
        }
    };

    const handleStartEdit = (field, value, dayIndex = null, exerciseIndex = null) => {
        setEditingField(field);
        setEditingValue(value);
        setEditingDayIndex(dayIndex);
        setEditingExerciseIndex(exerciseIndex);
    };

    const handleCancelEdit = () => {
        setEditingField(null);
        setEditingValue('');
        setEditingDayIndex(null);
        setEditingExerciseIndex(null);
    };

    const handleSaveEdit = async () => {
        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));

            if (editingField === 'name' || editingField === 'description') {
                newRutina[editingField] = editingValue;
            } else if (editingField === 'dayName') {
                const day = newRutina.days.find(d => d.dayIndex === editingDayIndex);
                if (day) {
                    day.dayName = editingValue;
                }
            } else if (editingField === 'exerciseName' || editingField === 'exerciseSets' || editingField === 'exerciseReps' || editingField === 'exerciseInstructions') {
                const day = newRutina.days.find(d => d.dayIndex === editingDayIndex);
                if (day && day.exercises[editingExerciseIndex]) {
                    const fieldMap = {
                        'exerciseName': 'name',
                        'exerciseSets': 'sets',
                        'exerciseReps': 'reps',
                        'exerciseInstructions': 'instructions'
                    };
                    const value = ['exerciseSets', 'exerciseReps'].includes(editingField) 
                        ? parseInt(editingValue) || 0 
                        : editingValue;
                    day.exercises[editingExerciseIndex][fieldMap[editingField]] = value;
                }
            }

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    ...newRutina,
                    updatedAt: new Date().toISOString()
                });
                setRutina(newRutina);
            }

            handleCancelEdit();
        } catch (error) {
            console.error("Error al guardar la edición:", error);
            alert('Error al guardar los cambios: ' + error.message);
        }
    };

    const handleOpenNewExerciseModal = (dayIndex) => {
        setNewExerciseDayIndex(dayIndex);
        setNewExercise({
            name: '',
            sets: '',
            reps: '',
            instructions: ''
        });
        setOpenNewExerciseModal(true);
    };

    const handleCloseNewExerciseModal = () => {
        setOpenNewExerciseModal(false);
        setNewExerciseDayIndex(null);
        setNewExercise({
            name: '',
            sets: '',
            reps: '',
            instructions: ''
        });
    };

    const handleAddNewExercise = async () => {
        if (!newExercise.name || !newExercise.sets) {
            alert('El nombre y las series son obligatorios');
            return;
        }

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const day = newRutina.days.find(d => d.dayIndex === newExerciseDayIndex);
            
            if (!day) {
                throw new Error('Día no encontrado');
            }

            if (!day.exercises) {
                day.exercises = [];
            }

            // Añadir el nuevo ejercicio con la estructura correcta
            day.exercises.push({
                name: newExercise.name,
                sets: parseInt(newExercise.sets),
                reps: newExercise.reps ? parseInt(newExercise.reps) : 0,
                instructions: newExercise.instructions || '',
                recursos: []
            });

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: new Date().toISOString()
                });
                setRutina(newRutina);
                handleCloseNewExerciseModal();
            }
        } catch (error) {
            console.error("Error al añadir ejercicio:", error);
            alert('Error al añadir el ejercicio: ' + error.message);
        }
    };

    const handleDeleteExercise = async (dayIndex, exerciseIndex) => {
        setDeleteExerciseData({ dayIndex, exerciseIndex });
        setDeleteConfirmOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const { dayIndex, exerciseIndex } = deleteExerciseData;
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const day = newRutina.days.find(d => d.dayIndex === dayIndex);
            
            if (!day) {
                throw new Error('Día no encontrado');
            }

            // Eliminar el ejercicio del array
            day.exercises.splice(exerciseIndex, 1);

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: new Date().toISOString()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al eliminar ejercicio:", error);
            alert('Error al eliminar el ejercicio: ' + error.message);
        } finally {
            setDeleteConfirmOpen(false);
            setDeleteExerciseData(null);
        }
    };

    const handleCancelDelete = () => {
        setDeleteConfirmOpen(false);
        setDeleteExerciseData(null);
    };

    const handleOpenNewDayModal = () => {
        setNewDay({
            dayName: '',
            weekDays: []
        });
        setOpenNewDayModal(true);
    };

    const handleCloseNewDayModal = () => {
        setOpenNewDayModal(false);
        setNewDay({
            dayName: '',
            weekDays: []
        });
    };

    const handleAddNewDay = async () => {
        if (!newDay.dayName) {
            alert('El nombre del día es obligatorio');
            return;
        }

        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            
            if (!newRutina.days) {
                newRutina.days = [];
            }

            // Encontrar el siguiente índice disponible
            const maxDayIndex = Math.max(...newRutina.days.map(d => d.dayIndex), 0);
            const newDayIndex = maxDayIndex + 1;

            // Añadir el nuevo día
            newRutina.days.push({
                dayIndex: newDayIndex,
                dayName: newDay.dayName,
                weekDays: newDay.weekDays,
                exercises: []
            });

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: new Date().toISOString()
                });
                setRutina(newRutina);
                handleCloseNewDayModal();
            }
        } catch (error) {
            console.error("Error al añadir día:", error);
            alert('Error al añadir el día: ' + error.message);
        }
    };

    const handleDeleteDay = (dayIndex) => {
        setDeleteDayIndex(dayIndex);
        setDeleteDayConfirmOpen(true);
    };

    const handleConfirmDeleteDay = async () => {
        try {
            const newRutina = JSON.parse(JSON.stringify(rutina));
            const dayIndex = newRutina.days.findIndex(d => d.dayIndex === deleteDayIndex);
            
            if (dayIndex === -1) {
                throw new Error('Día no encontrado');
            }

            // Eliminar el día
            newRutina.days.splice(dayIndex, 1);

            // Actualizar en Firestore
            const routinesRef = collection(db, 'routines');
            const q = query(
                routinesRef,
                where('IDPT', '==', userDetails.IDPT),
                where('clientId', '==', clientId),
                orderBy('createdAt', 'desc'),
                limit(1)
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const routineDoc = querySnapshot.docs[0];
                await updateDoc(doc(db, 'routines', routineDoc.id), {
                    days: newRutina.days,
                    updatedAt: new Date().toISOString()
                });
                setRutina(newRutina);
            }
        } catch (error) {
            console.error("Error al eliminar día:", error);
            alert('Error al eliminar el día: ' + error.message);
        } finally {
            setDeleteDayConfirmOpen(false);
            setDeleteDayIndex(null);
        }
    };

    const handleCancelDeleteDay = () => {
        setDeleteDayConfirmOpen(false);
        setDeleteDayIndex(null);
    };

    const filteredRecursos = recursos.filter(recurso => {
        const matchesSearch = recurso.titulo.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesType = selectedType === 'all' || recurso.fileType === selectedType;
        return matchesSearch && matchesType;
    });

    const renderResourceModal = () => {
        return (
            <Dialog 
                open={openResourceModal} 
                onClose={handleCloseResourceModal}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Añadir Recursos a {selectedExercise?.name}
                        </Typography>
                        <IconButton onClick={handleCloseResourceModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ mb: 3 }}>
                        {/* Filtros y búsqueda */}
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <Box sx={{ flex: 1 }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    placeholder="Buscar recursos..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
                                    }}
                                />
                            </Box>
                            <Box>
                                <Button
                                    variant={selectedType === 'all' ? 'contained' : 'outlined'}
                                    size="small"
                                    onClick={() => setSelectedType('all')}
                                    sx={{ mr: 1 }}
                                >
                                    Todos
                                </Button>
                                <Button
                                    variant={selectedType === 'video' ? 'contained' : 'outlined'}
                                    size="small"
                                    startIcon={<VideoLibraryIcon />}
                                    onClick={() => setSelectedType('video')}
                                    sx={{ mr: 1 }}
                                >
                                    Videos
                                </Button>
                                <Button
                                    variant={selectedType === 'imagen' ? 'contained' : 'outlined'}
                                    size="small"
                                    startIcon={<ImageIcon />}
                                    onClick={() => setSelectedType('imagen')}
                                    sx={{ mr: 1 }}
                                >
                                    Imágenes
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                    {loadingRecursos ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : filteredRecursos.length === 0 ? (
                        <Box sx={{ textAlign: 'center', py: 4 }}>
                            <Typography color="text.secondary">
                                No se encontraron recursos
                            </Typography>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', gap: 2, position: 'relative' }}>
                            <ImageList cols={3} gap={8} sx={{ width: '100%' }}>
                                {filteredRecursos.map((recurso) => (
                                    <ImageListItem 
                                        key={recurso.id}
                                        sx={{ 
                                            cursor: 'pointer',
                                            '&:hover': { 
                                                '& .resource-overlay': {
                                                    opacity: 1
                                                }
                                            },
                                            position: 'relative',
                                            borderRadius: 1,
                                            overflow: 'hidden',
                                            height: '240px !important'
                                        }}
                                        onClick={() => handleAddResource(recurso)}
                                        onMouseEnter={() => setPreviewResource(recurso)}
                                        onMouseLeave={() => setPreviewResource(null)}
                                    >
                                        <Box sx={{ height: '200px', overflow: 'hidden' }}>
                                            {recurso.fileType === 'video' ? (
                                                <video
                                                    src={recurso.fileUrl}
                                                    style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                                />
                                            ) : recurso.fileType === 'imagen' ? (
                                                <img
                                                    src={recurso.fileUrl}
                                                    alt={recurso.titulo}
                                                    style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                                />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        height: '200px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        bgcolor: 'grey.100'
                                                    }}
                                                >
                                                    <VideoLibraryIcon sx={{ fontSize: 60, color: 'primary.main' }} />
                                                </Box>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                bgcolor: 'background.paper',
                                                borderTop: '1px solid',
                                                borderColor: 'divider',
                                                p: 1,
                                                height: '40px',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Typography variant="subtitle2" noWrap>
                                                {recurso.titulo}
                                            </Typography>
                                        </Box>
                                        <Box
                                            className="resource-overlay"
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                height: '200px',
                                                bgcolor: 'rgba(0, 0, 0, 0.5)',
                                                color: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                opacity: 0,
                                                transition: 'opacity 0.2s'
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                Click para añadir
                                            </Typography>
                                        </Box>
                                    </ImageListItem>
                                ))}
                            </ImageList>

                            {/* Preview Panel - Fixed position */}
                            {previewResource && (
                                <Box 
                                    sx={{ 
                                        position: 'absolute',
                                        top: 0,
                                        right: -320,
                                        width: '300px',
                                        p: 2,
                                        bgcolor: 'grey.50',
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        boxShadow: 2,
                                        zIndex: 1
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {previewResource.titulo}
                                    </Typography>
                                    <Box sx={{ mb: 2 }}>
                                        <Chip 
                                            label={previewResource.fileType}
                                            color="primary"
                                            size="small"
                                        />
                                    </Box>
                                    {previewResource.fileType === 'video' && (
                                        <video
                                            src={previewResource.fileUrl}
                                            controls
                                            style={{ width: '100%', borderRadius: 4 }}
                                        />
                                    )}
                                    {previewResource.fileType === 'imagen' && (
                                        <img
                                            src={previewResource.fileUrl}
                                            alt={previewResource.titulo}
                                            style={{ width: '100%', borderRadius: 4 }}
                                        />
                                    )}
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={() => handleAddResource(previewResource)}
                                        sx={{ mt: 2 }}
                                    >
                                        Añadir Recurso
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    };

    const renderNewExerciseModal = () => {
        return (
            <Dialog
                open={openNewExerciseModal}
                onClose={handleCloseNewExerciseModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Nuevo Ejercicio
                        </Typography>
                        <IconButton onClick={handleCloseNewExerciseModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
                        <TextField
                            label="Nombre del ejercicio"
                            value={newExercise.name}
                            onChange={(e) => setNewExercise({ ...newExercise, name: e.target.value })}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Series"
                            type="number"
                            value={newExercise.sets}
                            onChange={(e) => setNewExercise({ ...newExercise, sets: e.target.value })}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Repeticiones"
                            type="number"
                            value={newExercise.reps}
                            onChange={(e) => setNewExercise({ ...newExercise, reps: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            label="Instrucciones"
                            multiline
                            rows={4}
                            value={newExercise.instructions}
                            onChange={(e) => setNewExercise({ ...newExercise, instructions: e.target.value })}
                            fullWidth
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewExerciseModal}>
                        Cancelar
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={handleAddNewExercise}
                        disabled={!newExercise.name || !newExercise.sets}
                    >
                        Añadir Ejercicio
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderNewDayModal = () => {
        return (
            <Dialog
                open={openNewDayModal}
                onClose={handleCloseNewDayModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">
                            Nuevo Día
                        </Typography>
                        <IconButton onClick={handleCloseNewDayModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
                        <TextField
                            label="Nombre del día"
                            value={newDay.dayName}
                            onChange={(e) => setNewDay({ ...newDay, dayName: e.target.value })}
                            fullWidth
                            required
                        />
                        <Typography variant="subtitle2" gutterBottom>
                            Días de la semana
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {diasSemana.map((dia) => (
                                <Chip
                                    key={dia}
                                    label={dia}
                                    onClick={() => {
                                        const weekDays = newDay.weekDays.includes(dia)
                                            ? newDay.weekDays.filter(d => d !== dia)
                                            : [...newDay.weekDays, dia];
                                        setNewDay({ ...newDay, weekDays });
                                    }}
                                    color={newDay.weekDays.includes(dia) ? "primary" : "default"}
                                    variant={newDay.weekDays.includes(dia) ? "filled" : "outlined"}
                                />
                            ))}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewDayModal}>
                        Cancelar
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={handleAddNewDay}
                        disabled={!newDay.dayName}
                    >
                        Añadir Día
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderExercise = (exercise, dayIndex, exerciseIndex) => {
        return (
            <Box
                sx={{
                    p: 2,
                    mb: 2,
                    borderRadius: 2,
                    backgroundColor: 'background.default',
                    border: '1px solid',
                    borderColor: 'divider'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                        {editingField === 'exerciseName' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Typography 
                                variant="subtitle1" 
                                fontWeight="bold"
                                onClick={() => handleStartEdit('exerciseName', exercise.name, dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 0.5,
                                    borderRadius: 1
                                }}
                            >
                                {exercise.name}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            startIcon={<AddIcon />}
                            size="small"
                            onClick={() => handleOpenResourceModal(exercise, dayIndex, exerciseIndex)}
                        >
                            Añadir Recurso
                        </Button>
                        <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleDeleteExercise(dayIndex, exerciseIndex)}
                            sx={{
                                '&:hover': {
                                    bgcolor: 'error.light',
                                    color: 'white'
                                }
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 3,
                            p: 1.5,
                            bgcolor: 'grey.50',
                            borderRadius: 2,
                            border: '1px solid',
                            borderColor: 'divider',
                            width: '100%'
                        }}
                    >
                        {editingField === 'exerciseSets' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                    type="number"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box 
                                onClick={() => handleStartEdit('exerciseSets', exercise.sets.toString(), dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 1,
                                    borderRadius: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    minWidth: 100
                                }}
                            >
                                <Typography variant="h4" color="primary" fontWeight="bold">
                                    {exercise.sets}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Series
                                </Typography>
                            </Box>
                        )}

                        {editingField === 'exerciseReps' && 
                         editingDayIndex === dayIndex && 
                         editingExerciseIndex === exerciseIndex ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                    autoFocus
                                    type="number"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box 
                                onClick={() => handleStartEdit('exerciseReps', (exercise.reps || 0).toString(), dayIndex, exerciseIndex)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 1,
                                    borderRadius: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    minWidth: 100,
                                    borderLeft: '1px solid',
                                    borderColor: 'divider'
                                }}
                            >
                                <Typography variant="h4" color="primary" fontWeight="bold">
                                    {exercise.reps || 0}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Repeticiones
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                {editingField === 'exerciseInstructions' && 
                 editingDayIndex === dayIndex && 
                 editingExerciseIndex === exerciseIndex ? (
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            value={editingValue}
                            onChange={(e) => setEditingValue(e.target.value)}
                            variant="outlined"
                            size="small"
                            autoFocus
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                            <Button size="small" onClick={handleCancelEdit}>
                                Cancelar
                            </Button>
                            <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                ) : exercise.instructions ? (
                    <Box sx={{ 
                        mt: 2,
                        p: 2,
                        backgroundColor: 'grey.50',
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: 'grey.200',
                        cursor: 'pointer',
                        '&:hover': { bgcolor: 'grey.100' }
                    }}
                    onClick={() => handleStartEdit('exerciseInstructions', exercise.instructions, dayIndex, exerciseIndex)}
                    >
                        <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-wrap' }}>
                            {exercise.instructions}
                        </Typography>
                    </Box>
                ) : (
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => handleStartEdit('exerciseInstructions', '', dayIndex, exerciseIndex)}
                        sx={{ mt: 2 }}
                        size="small"
                    >
                        Añadir instrucciones
                    </Button>
                )}
                
                {exercise.recursos && exercise.recursos.length > 0 && (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Recursos:
                        </Typography>
                        <ImageList cols={3} gap={8} sx={{ mt: 1 }}>
                            {exercise.recursos.map((recurso) => (
                                <ImageListItem 
                                    key={recurso.id}
                                    sx={{ position: 'relative' }}
                                >
                                    {recurso.tipo === 'video' ? (
                                        <video
                                            src={recurso.url}
                                            controls
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    ) : recurso.tipo === 'imagen' ? (
                                        <img
                                            src={recurso.url}
                                            alt={recurso.titulo}
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                bgcolor: 'grey.100',
                                                p: 2
                                            }}
                                        >
                                            <VideoLibraryIcon sx={{ fontSize: 40 }} />
                                            <Typography variant="caption" sx={{ mt: 1 }}>
                                                {recurso.titulo}
                                            </Typography>
                                        </Box>
                                    )}
                                    <IconButton
                                        size="small"
                                        sx={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            bgcolor: 'rgba(0, 0, 0, 0.5)',
                                            '&:hover': {
                                                bgcolor: 'rgba(0, 0, 0, 0.7)',
                                            },
                                            color: 'white'
                                        }}
                                        onClick={() => handleDeleteResource(dayIndex, exerciseIndex, recurso.id)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                )}
            </Box>
        );
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ m: 3 }}>
                {error}
            </Alert>
        );
    }

    if (!rutina) {
        return (
            <Alert severity="info" sx={{ m: 3 }}>
                No hay rutina asignada para este cliente
            </Alert>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Paper elevation={1} sx={{ p: 3, borderRadius: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <Box>
                        {editingField === 'name' ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    value={editingValue}
                                    onChange={(e) => setEditingValue(e.target.value)}
                                    variant="standard"
                                    autoFocus
                                    size="small"
                                />
                                <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                    <CheckIcon />
                                </IconButton>
                                <IconButton size="small" onClick={handleCancelEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Typography 
                                variant="h6" 
                                gutterBottom
                                onClick={() => handleStartEdit('name', rutina.name)}
                                sx={{ 
                                    cursor: 'pointer',
                                    '&:hover': { bgcolor: 'action.hover' },
                                    p: 0.5,
                                    borderRadius: 1
                                }}
                            >
                                {rutina.name}
                            </Typography>
                        )}
                        <Typography variant="body2" color="text.secondary">
                            Última actualización: {new Date(rutina.updatedAt).toLocaleDateString()}
                        </Typography>
                    </Box>
                </Box>

                {editingField === 'description' ? (
                    <Box sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            value={editingValue}
                            onChange={(e) => setEditingValue(e.target.value)}
                            variant="outlined"
                            size="small"
                            autoFocus
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, gap: 1 }}>
                            <Button size="small" onClick={handleCancelEdit}>
                                Cancelar
                            </Button>
                            <Button size="small" variant="contained" onClick={handleSaveEdit}>
                                Guardar
                            </Button>
                        </Box>
                    </Box>
                ) : rutina.description ? (
                    <Box sx={{ 
                        p: 2, 
                        mb: 3, 
                        backgroundColor: 'info.main', 
                        borderRadius: 2,
                        display: 'flex',
                        gap: 1,
                        alignItems: 'flex-start',
                        cursor: 'pointer',
                        '&:hover': { opacity: 0.9 }
                    }}
                    onClick={() => handleStartEdit('description', rutina.description)}
                    >
                        <InfoIcon sx={{ color: 'white', mt: 0.5 }} />
                        <Typography variant="body2" sx={{ color: 'white' }}>
                            {rutina.description}
                        </Typography>
                    </Box>
                ) : (
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => handleStartEdit('description', '')}
                        sx={{ mb: 3 }}
                    >
                        Añadir descripción
                    </Button>
                )}

                {rutina.days?.map((day) => (
                    <Accordion
                        key={day.dayIndex}
                        defaultExpanded={true}
                        sx={{
                            mb: 2,
                            backgroundColor: 'white',
                            '&:before': { display: 'none' },
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                            borderRadius: '8px !important',
                            overflow: 'hidden'
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                backgroundColor: 'background.default',
                                '& .MuiAccordionSummary-content': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                }
                            }}
                        >
                            <FitnessCenterIcon color="primary" />
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                                <Typography variant="subtitle1" fontWeight="500">
                                    Día {day.dayIndex}
                                </Typography>
                                {editingField === 'dayName' && editingDayIndex === day.dayIndex ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <TextField
                                            value={editingValue}
                                            onChange={(e) => setEditingValue(e.target.value)}
                                            variant="standard"
                                            size="small"
                                            autoFocus
                                        />
                                        <IconButton size="small" onClick={handleSaveEdit} color="primary">
                                            <CheckIcon />
                                        </IconButton>
                                        <IconButton size="small" onClick={handleCancelEdit}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                ) : (
                                    <Chip 
                                        label={day.dayName} 
                                        size="small" 
                                        color="primary" 
                                        variant="outlined"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleStartEdit('dayName', day.dayName, day.dayIndex);
                                        }}
                                        sx={{ cursor: 'pointer' }}
                                    />
                                )}
                                <Box sx={{ flex: 1 }} />
                                <Button
                                    size="small"
                                    startIcon={<CalendarIcon />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDayClick(e, day.dayIndex);
                                    }}
                                    sx={{ ml: 2 }}
                                >
                                    Días de la semana
                                </Button>
                                {day.weekDays && day.weekDays.length > 0 && (
                                    <Typography variant="caption" color="text.secondary">
                                        ({day.weekDays.join(', ')})
                                    </Typography>
                                )}
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteDay(day.dayIndex);
                                    }}
                                    sx={{
                                        ml: 1,
                                        '&:hover': {
                                            bgcolor: 'error.light',
                                            color: 'white'
                                        }
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 2 }}>
                            {day.exercises?.length > 0 ? (
                                <>
                                    {day.exercises.map((exercise, exerciseIndex) => (
                                        <React.Fragment key={exerciseIndex}>
                                            {renderExercise(exercise, day.dayIndex, exerciseIndex)}
                                        </React.Fragment>
                                    ))}
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => handleOpenNewExerciseModal(day.dayIndex)}
                                        sx={{ mt: 2 }}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        Añadir Ejercicio
                                    </Button>
                                </>
                            ) : (
                                <Box sx={{ textAlign: 'center', py: 2 }}>
                                    <Typography variant="body1" color="text.secondary" gutterBottom>
                                        No hay ejercicios programados para este día
                                    </Typography>
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => handleOpenNewExerciseModal(day.dayIndex)}
                                        variant="outlined"
                                    >
                                        Añadir Ejercicio
                                    </Button>
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Button
                    startIcon={<AddIcon />}
                    onClick={handleOpenNewDayModal}
                    variant="contained"
                    fullWidth
                    sx={{ 
                        mt: 2,
                        bgcolor: 'grey.200',
                        color: 'text.primary',
                        '&:hover': {
                            bgcolor: 'grey.300'
                        }
                    }}
                >
                    Añadir Día
                </Button>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleDayClose}
                >
                    {diasSemana.map((dia) => (
                        <MenuItem 
                            key={dia} 
                            onClick={() => handleDaySelect(dia)}
                            sx={{ minWidth: 200 }}
                        >
                            <Checkbox 
                                checked={rutina?.days
                                    ?.find(d => d.dayIndex === selectedDayIndex)
                                    ?.weekDays?.includes(dia) || false}
                            />
                            <ListItemText primary={dia} />
                        </MenuItem>
                    ))}
                </Menu>
            </Paper>
            {renderResourceModal()}
            {renderNewExerciseModal()}
            {renderNewDayModal()}
            <Dialog
                open={deleteConfirmOpen}
                onClose={handleCancelDelete}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que quieres eliminar este ejercicio?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleConfirmDelete} 
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteDayConfirmOpen}
                onClose={handleCancelDeleteDay}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    <Typography>
                        ¿Estás seguro de que quieres eliminar este día?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDeleteDay}>
                        Cancelar
                    </Button>
                    <Button 
                        onClick={handleConfirmDeleteDay} 
                        color="error"
                        variant="contained"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default RoutineView; 